import type { EntityAdapter, EntityState, PayloadAction } from '@reduxjs/toolkit';
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { Toast } from '../../types/toast.model';
import { RootState } from './../store';

const toastsEntityAdapter: EntityAdapter<Toast, string> = createEntityAdapter<Toast>();

type LayoutState = {
  hamburgerActive: boolean;
  toasts: EntityState<Toast, string>;
};

const initialState: LayoutState = {
  hamburgerActive: false,
  toasts: toastsEntityAdapter.getInitialState()
};

const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    setHamburgerActive: (
      state: LayoutState,
      action: PayloadAction<boolean>
    ) => {
      state.hamburgerActive = action.payload;
    },
    addToast: (state: LayoutState, action: PayloadAction<Toast>) => {
      toastsEntityAdapter.addOne(state.toasts, action.payload);
    },
    removeToasts: (state: LayoutState, action: PayloadAction<string[]>) => {
      toastsEntityAdapter.removeMany(state.toasts, action.payload);
    }
  }
});

export const layoutActions = {
  setHamburgerActive: layoutSlice.actions.setHamburgerActive,
  addToast: layoutSlice.actions.addToast,
  removeToasts: layoutSlice.actions.removeToasts
};

export const layoutSelectors = {
  selectHamburgerActive: (state: RootState) => state.layout.hamburgerActive,
  selectToasts: toastsEntityAdapter.getSelectors<RootState>(state => state.layout.toasts).selectAll
};

export const layoutReducer = layoutSlice.reducer;
