import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { Project } from '../../types/projects.model';
import type { RootState } from '../store';

type ProjectsState = {
  mutationProject: Project | null;
};

const initialState: ProjectsState = {
  mutationProject: null
};

const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    setMutationProject: (
      state: ProjectsState,
      action: PayloadAction<Project | null>
    ) => {
      state.mutationProject = action.payload;
    }
  }
});

export const projectsActions = {
  setMutationProject: projectsSlice.actions.setMutationProject
};

export const projectsSelectors = {
  selectMutationProject: (state: RootState) => state.projects.mutationProject
};

export const projectsReducer = projectsSlice.reducer;
