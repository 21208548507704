import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { routeLabels, RouteNames } from '../../constants/routes';
import {
  layoutActions,
  layoutSelectors
} from '../../redux/slices/layout.slice';
import { useAppDispatch, useAppSelector } from '../../redux/store';

interface Props {
  className?: string;
}

const Hamburger = ({ className }: Props) => {
  const hamburgerActive: boolean = useAppSelector(
    layoutSelectors.selectHamburgerActive
  );

  const dispatch = useAppDispatch();

  const handleToggle = (status: boolean) => {
    dispatch(layoutActions.setHamburgerActive(status));
  };

  return (
    <div className={clsx(className)}>
      <div className="flex flex-row justify-end">
        <div
          role="menubar"
          tabIndex={0}
          className="flex flex-col h-6 w-8 justify-between hover:cursor-pointer group"
          onClick={() => handleToggle(!hamburgerActive)}
          onKeyDown={() => handleToggle(!hamburgerActive)}
        >
          <span
            className={clsx(
              { 'rotate-45 translate-y-2.5': hamburgerActive },
              'w-full rounded-lg h-1 bg-zinc-200 group-hover:bg-orange-300 transition-all duration-[400ms]'
            )}
          ></span>
          <span
            className={clsx(
              { 'scale-0': hamburgerActive },
              'w-full rounded-lg h-1 bg-zinc-200 group-hover:bg-orange-300 transition-all duration-[400ms]'
            )}
          ></span>
          <span
            className={clsx(
              { '-rotate-45 -translate-y-2.5': hamburgerActive },
              'w-full rounded-lg h-1 bg-zinc-200 group-hover:bg-orange-300 transition-all duration-[400ms]'
            )}
          ></span>
        </div>
      </div>

      <div
        className={clsx(
          {
            'translate-x-full opacity-0': !hamburgerActive,
            'translate-x-0 opacity-100': hamburgerActive
          },
          'absolute w-full top-14 right-0 bg-zinc-950/95 transition-all duration-500 flex flex-col items-center'
        )}
      >
        <Link
          role="menuitem"
          className="hover:text-blue-500 hover:scale-125 transition-all"
          to={RouteNames.ABOUT}
          onClick={() => handleToggle(false)}
        >
          <h5 className="py-2">{routeLabels[RouteNames.ABOUT]}</h5>
        </Link>
        <Link
          role="menuitem"
          className="hover:text-blue-500 hover:scale-125 transition-all"
          to={RouteNames.PROJECTS}
          onClick={() => handleToggle(false)}
        >
          <h5 className="pb-2">{routeLabels[RouteNames.PROJECTS]}</h5>
        </Link>
        <Link
          role="menuitem"
          className="hover:text-blue-500 hover:scale-125 transition-all"
          to={RouteNames.SKILLS}
          onClick={() => handleToggle(false)}
        >
          <h5 className="pb-2">{routeLabels[RouteNames.SKILLS]}</h5>
        </Link>
        <Link
          role="menuitem"
          className="hover:text-blue-500 hover:scale-125 transition-all"
          to={RouteNames.TOOLS}
          onClick={() => handleToggle(false)}
        >
          <h5 className="pb-2">{routeLabels[RouteNames.TOOLS]}</h5>
        </Link>
        <Link
          role="menuitem"
          className="hover:text-blue-500 hover:scale-125 transition-all"
          to={RouteNames.SITE_ITEMS}
          onClick={() => handleToggle(false)}
        >
          <h5 className="pb-2">{routeLabels[RouteNames.SITE_ITEMS]}</h5>
        </Link>
      </div>
    </div>
  );
};

export default Hamburger;
