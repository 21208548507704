import { createApi } from '@reduxjs/toolkit/query/react';
import type {
  ApiResponseWrapper,
  GenericResponseResult
} from '../../types/common.model';
import type {
  CreateSkill,
  CreateSkillGroup,
  Skill,
  SkillGroup,
  UpdateSkill,
  UpdateSkillGroup
} from '../../types/skills.model';
import { baseQuery } from './baseClient';

export const skillsApi = createApi({
  reducerPath: 'skillsApi',
  baseQuery: baseQuery({ baseUrl: 'skills' }),
  tagTypes: ['Skills', 'SkillGroups'],
  endpoints: (builder) => ({
    /* SKILLS */
    getSkills: builder.query<ApiResponseWrapper<Skill[]>, null>({
      query: () => ({ url: '/items', method: 'GET' }),
      providesTags: ['Skills']
    }),
    getSkillById: builder.query<ApiResponseWrapper<Skill>, string>({
      query: (id) => ({ url: `/item/${id}`, method: 'GET' }),
      providesTags: ['Skills']
    }),
    createSkill: builder.mutation<ApiResponseWrapper<Skill>, CreateSkill>({
      query: (body) => ({ url: '/item', method: 'POST', body }),
      invalidatesTags: ['Skills']
    }),
    updateSkill: builder.mutation<
      ApiResponseWrapper<Skill>,
      { id: string; body: UpdateSkill }
    >({
      query: ({ id, body }) => ({ url: `/item/${id}`, method: 'PATCH', body }),
      invalidatesTags: ['Skills']
    }),
    deleteSkill: builder.mutation<
      ApiResponseWrapper<GenericResponseResult>,
      string
    >({
      query: (id) => ({ url: `/item/${id}`, method: 'DELETE' }),
      invalidatesTags: ['Skills']
    }),

    /* SKILL GROUPS */
    getSkillGroups: builder.query<ApiResponseWrapper<SkillGroup[]>, null>({
      query: () => ({ url: '/groups', method: 'GET' }),
      providesTags: ['SkillGroups']
    }),
    getSkillGroupById: builder.query<ApiResponseWrapper<SkillGroup>, string>({
      query: (id) => ({ url: `/group/${id}`, method: 'GET' }),
      providesTags: ['SkillGroups']
    }),
    createSkillGroup: builder.mutation<
      ApiResponseWrapper<SkillGroup>,
      CreateSkillGroup
    >({
      query: (body) => ({ url: '/group', method: 'POST', body }),
      invalidatesTags: ['SkillGroups']
    }),
    updateSkillGroup: builder.mutation<
      ApiResponseWrapper<SkillGroup>,
      { id: string; body: UpdateSkillGroup }
    >({
      query: ({ id, body }) => ({ url: `/group/${id}`, method: 'PATCH', body }),
      invalidatesTags: ['SkillGroups']
    }),
    deleteSkillGroup: builder.mutation<
      ApiResponseWrapper<GenericResponseResult>,
      string
    >({
      query: (id) => ({ url: `/group/${id}`, method: 'DELETE' }),
      invalidatesTags: ['SkillGroups']
    })
  })
});

export const {
  useGetSkillsQuery,
  useGetSkillByIdQuery,
  useCreateSkillMutation,
  useUpdateSkillMutation,
  useDeleteSkillMutation,
  useGetSkillGroupsQuery,
  useGetSkillGroupByIdQuery,
  useCreateSkillGroupMutation,
  useUpdateSkillGroupMutation,
  useDeleteSkillGroupMutation
} = skillsApi;
