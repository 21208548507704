import clsx from 'clsx';
import throttle from 'lodash/throttle';
import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import LogoSvg from '../../assets/tree-solid.svg?react';
import './Logo.css';

interface Props {
  className?: string;
}

const Logo = ({ className }: Props) => {
  const [isNavbarSticky, setIsNavbarSticky] = useState(false);
  const imgContainerRef = useRef<HTMLDivElement>(null);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const scrollThrottle = throttle((_event: Event) => {
    const value = Math.floor(window.scrollY / 5);
    imgContainerRef.current?.style.setProperty(
      'transform',
      `rotate(${value}deg)`
    );

    if (document.documentElement.scrollTop > 20) {
      setIsNavbarSticky(true);
    } else {
      setIsNavbarSticky(false);
    }
  }, 50);

  useEffect(() => {
    window.addEventListener('scroll', scrollThrottle);
    return () => {
      window.removeEventListener('scroll', scrollThrottle);
    };
  }, [scrollThrottle]);

  return (
    <div
      className={clsx(
        className,
        {
          redLogo: isNavbarSticky,
          blueLogo: !isNavbarSticky,
          radialGradientDark: !isNavbarSticky
        },
        'logoContainer fixed top-0 left-0 p-2 transition-transform duration-50 ease-linear'
      )}
      ref={imgContainerRef}
    >
      <Link to="/">
        <LogoSvg
          className={clsx('w-10 h-10 hover:brightness-200 transition-all')}
        />
      </Link>
    </div>
  );
};

export default Logo;
