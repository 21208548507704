import clsx from 'clsx';
import { ReactElement, useMemo } from 'react';
import { Location, useLocation } from 'react-router-dom';
import AiAlgoSvg from '../../assets/ai-algo.svg?react';
import BriefcaseSvg from '../../assets/briefcase.svg?react';
import GitBranchSvg from '../../assets/git-branch-outline.svg?react';
import MicroscopeSvg from '../../assets/microscope.svg?react';
import NotFoundSvg from '../../assets/not-found.svg?react';
import SpreadsheetSvg from '../../assets/spreadsheet.svg?react';
import UniversitySvg from '../../assets/university.svg?react';
import UserShieldSvg from '../../assets/user-shield.svg?react';
import { RouteNames } from '../../constants/routes';
import { usersSelectors } from '../../redux/slices/users.slice';
import { useAppSelector } from '../../redux/store';
import Loader from '../Loader/Loader';
import './Page.css';

interface BaseProps {
  children: React.ReactNode | React.ReactNode[];
  loading: boolean;
  className?: string;
  hideLoader?: boolean;
}

interface CustomTitlePageProps extends BaseProps {
  titleComponent: React.ReactNode;
  title?: never;
}

interface GenericTitlePageProps extends BaseProps {
  titleComponent?: never;
  title: string;
}

type Props = CustomTitlePageProps | GenericTitlePageProps;

const routeIconMapping: Record<RouteNames, ReactElement<React.FunctionComponent<React.SVGProps<SVGSVGElement>>>> & Record<string, ReactElement<React.FunctionComponent<React.SVGProps<SVGSVGElement>>>> = {
  [RouteNames.HOME]: <GitBranchSvg className={clsx('svgIcon', 'w-10 h-10 mr-4')} />,
  [RouteNames.ABOUT]: <UserShieldSvg className={clsx('svgIcon', 'w-10 h-10 mr-4')} />,
  [RouteNames.PROJECTS]: <BriefcaseSvg className={clsx('svgIcon', 'w-10 h-10 mr-4')} />,
  [RouteNames.SKILLS]: <UniversitySvg className={clsx('svgIcon', 'w-10 h-10 mr-4')} />,
  [RouteNames.TOOLS]: <SpreadsheetSvg className={clsx('svgIcon', 'w-10 h-10 mr-4')} />,
  [RouteNames.SITE_ITEMS]: <MicroscopeSvg className={clsx('svgIcon', 'w-10 h-10 mr-4')} />,
  [RouteNames.AI]: <AiAlgoSvg className={clsx('svgIcon', 'w-10 h-10 mr-4')} />,
  [RouteNames.NOT_FOUND]: <NotFoundSvg className={clsx('svgIcon', 'w-10 h-10 mr-4')} />
}

const Page = ({ className, children, titleComponent, title, loading, hideLoader = false }: Props) => {

  const currentUser = useAppSelector(usersSelectors.selectCurrentUser);
  const location: Location = useLocation();

  const pickedIconElement = useMemo<ReactElement<React.FunctionComponent<React.SVGProps<SVGSVGElement>>>>(() => {
    const foundElement = routeIconMapping[location.pathname];
    return foundElement ?? <NotFoundSvg className={clsx('svgIcon', 'w-10 h-10 mr-4')} />;
  }, [location.pathname])

  return (
    <div className={clsx('pt-10 sm:pt-14 md:pt-18', className)}>
      <div className="pb-10 sm:pb-14 md:pb-18 font-museo">
        {
          titleComponent ?
            titleComponent :
            (
              <div className="flex flex-row justify-center items-center">
                {pickedIconElement ?? null}
                <h2 className={clsx('genericTitle', 'flex flex-row justify-center')}>{title}</h2>
              </div>
            )
        }
      </div>
      {
        loading ? (
          hideLoader ? null : <Loader />
        ) : (
          currentUser ? (
            <div className="pb-4">
              {children}
            </div>
          ) : (
            <div className="flex flex-row justify-center items-center">
              <div className='notFoundIconContainer'>
                <NotFoundSvg className='w-8 h-8 mb-2 m-auto' />
                <div className="font-exo text-red-500">Login Error</div>
              </div>
            </div>
          )
        )
      }
    </div>
  )
}

export default Page;
