import { jwtDecode } from 'jwt-decode';
import { StorageKeys } from '../constants/storageKeys';

export class StorageService {
  static setAccessToken(token: string) {
    localStorage.setItem(StorageKeys.ACCESS_TOKEN, token);
  }

  static getAccessToken() {
    const token = localStorage.getItem(StorageKeys.ACCESS_TOKEN);
    if (!token) {
      return null;
    }
    return token;
  }

  static isAccessTokenValid() {
    const token = this.getAccessToken();
    let expirationEpoch = Number.MIN_SAFE_INTEGER;
    if (token) {
      expirationEpoch =
        (jwtDecode(token).exp ?? Number.MIN_SAFE_INTEGER) * 1000;
    }
    return Date.now() <= expirationEpoch;
  }

  static clearSessionData() {
    localStorage.removeItem(StorageKeys.ACCESS_TOKEN);
  }
}
