import type { BaseQueryFn } from '@reduxjs/toolkit/query';
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import axios from 'axios';
import { BaseUser } from '../../constants/baseUser';
import { environmentVariables } from '../../constants/environment';
import { StorageService } from '../../services/storage.service';
import type { ApiResponseWrapper } from '../../types/common.model';
import type { LoginResponse } from '../../types/users.model';

const NETWORK_ERROR = 'Network Error';
const INVALID_CREDENTIALS_MESSAGE = 'Invalid credentials';

const client = axios.create({
  baseURL: `${environmentVariables.VITE_API_URL}/api`,
  timeout: 10000 // 10 seconds
});

client.interceptors.request.use((config) => {
  config.headers['Accept-Language'] = 'en';

  if (config?.data instanceof FormData) {
    config.timeout = 60000; // 1 minute timeout for media uploads
  }
  const accessToken = StorageService.getAccessToken();
  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }

  return config;
});

client.interceptors.response.use(
  (response) => response,
  async (error) => {
    const requestConfig = error.config

    const errorMessage = error.response?.data?.message
    const isLoginPassMismatch: boolean = errorMessage === INVALID_CREDENTIALS_MESSAGE

    if (
      ((error?.response?.status === 401 && !isLoginPassMismatch) || error?.message === NETWORK_ERROR) &&
      !requestConfig._retry
    ) {
      try {
        requestConfig._retry = true;
        const tokenResponse: AxiosResponse<ApiResponseWrapper<LoginResponse>> =
          await axios.request<ApiResponseWrapper<LoginResponse>>({
            url: `${environmentVariables.VITE_API_URL}/api/users/login`,
            method: 'POST',
            data: { email: BaseUser.EMAIL, password: BaseUser.PASSWORD }
          });
        StorageService.setAccessToken(tokenResponse.data.response.accessToken);
        const accessToken = StorageService.getAccessToken();
        axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
        return client(requestConfig);
      } catch (refreshError) {
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  }
);

export const baseQuery =
  (
    { baseUrl }: { baseUrl: string } = { baseUrl: '' }
  ): BaseQueryFn<
    {
      url: string;
      method?: AxiosRequestConfig['method'];
      data?: AxiosRequestConfig['data'];
      params?: AxiosRequestConfig['params'];
      headers?: AxiosRequestConfig['headers'];
    },
    unknown,
    unknown
  > =>
  async ({ url, method, data, params, headers }) => {
    try {
      const result = await client({
        url: baseUrl + url,
        method,
        data,
        params,
        headers
      });
      return { data: result.data };
    } catch (axiosError) {
      const err = axiosError as AxiosError;
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message
        }
      };
    }
  };
