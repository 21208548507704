import Colors from 'tailwindcss/colors';

// INFO - animation is adjusted to certain number of items
export enum MotivationalWords {
  INNOVATION = "Innovation",
  EFFICENCY = "Efficency",
  DELIVERY = "Delivery",
  CLOUD = "The Cloud",
  CLEAN_CODE = "Clean Code",
}

export const motivationalList: MotivationalWords[] = Object.values(MotivationalWords);

export const wordColorMapping: Record<MotivationalWords, string> = {
  [MotivationalWords.EFFICENCY]: Colors.blue[600],
  [MotivationalWords.DELIVERY]: Colors.blue[600],
  [MotivationalWords.INNOVATION]: Colors.blue[600],
  [MotivationalWords.CLOUD]: Colors.blue[600],
  [MotivationalWords.CLEAN_CODE]: Colors.blue[600],
};
