export enum Environment {
  DEVELOPMENT = 'development',
  PRODUCTION = 'production'
}
export interface AppConfig {
  VITE_PORT: string;
  VITE_API_URL: string;
  VITE_API_BASE_USER_EMAIL: string;
  VITE_API_BASE_USER_PASSWORD: string;
  VITE_FILE_BUCKET_PATH: string;

  MODE: string
  // DEV: boolean
  // PROD: boolean
}
export enum AppConfigKeys {
  VITE_PORT = 'VITE_PORT',
  VITE_API_URL = 'VITE_API_URL',
  VITE_API_BASE_USER_EMAIL = 'VITE_API_BASE_USER_EMAIL',
  VITE_API_BASE_USER_PASSWORD = 'VITE_API_BASE_USER_PASSWORD',
  VITE_FILE_BUCKET_PATH = 'VITE_FILE_BUCKET_PATH'
}

export const filenameEnvMapping: { [key: string]: string } = {
  [Environment.DEVELOPMENT]: '.env.development',
  [Environment.PRODUCTION]: '.env.production'
};

export const environmentVariables: AppConfig = import.meta.env as unknown as AppConfig;
