import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import Tile from '../../components/Tile/Tile';
import { ParallaxBanner } from 'react-scroll-parallax';
import { composeFileFolderPath, FileTypes, IMAGE_NOT_ALLOWED_NAME } from '../../constants/files';
import NotFoundSvg from '../../assets/not-found.svg?react';
import './NotFound.css';

export interface Props {
  className?: string;
}

const NotFound = ({ className }: Props) => {

  const navigate = useNavigate();

  return (
    <div className={clsx(className, 'font-exo')}>
      <Tile className="relative">
        <ParallaxBanner
          layers={[{ image: `${composeFileFolderPath(FileTypes.IMAGE_COMMON)}${IMAGE_NOT_ALLOWED_NAME}`, speed: -20 }]}
          className="max-h-40 md:max-h-50 lg:max-h-60 xl:max-h-70 aspect-[2/1] md:aspect-[3/1] lg:aspect-[4/1] rounded-2xl object-cover mt-3"
        >
          <div className="notAllowedIcon absolute right-1 md:right-5 top-1 md:top-5 bg-neutral-800/70 p-2 rounded-full">
            <NotFoundSvg className={clsx('svgIcon', 'w-7 md:w-8 lg:w-9 h-7 md:h-8 lg-h-9')} />
          </div>
        </ParallaxBanner>
        <div className="text-lg text-center pb-8 mt-8">Provided route not found within available pages.</div>
        <div className="flex flex-row justify-center">
          <button
            className="bg-blue-600 hover:bg-blue-500 py-3 px-4 rounded-md uppercase cursor-pointer"
            onClick={() => navigate('/')}
          >Go Home</button>
        </div>
      </Tile>
    </div>
  );
};

export default NotFound;
