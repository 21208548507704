import clsx from 'clsx';
import { ParallaxBanner } from 'react-scroll-parallax';
import UserShieldSvg from '../../assets/user-shield.svg?react';
import Tile from '../../components/Tile/Tile';
import { composeFileFolderPath, FileTypes, IMAGE_HOME_BG_NAME } from '../../constants/files';
import './About.css';
import Carousel from '../../components/Carousel/Carousel';

export interface Props {
  className?: string;
}

const workXp = `
With over a decade of experience in the IT sector, I have honed a diverse skill set that spans: software development,
design, team leadership, and IT consulting.

Throughout my career, I have worked with a variety of teams,
placing great importance on clear communication, fostering a positive work atmosphere, and ensuring high-quality solutions.

`

const personalityTraits = `
Having worked on diverse and complex projects, I put emphasis on reliability and independence.

That goes in par with my personality. I’m known for being honest, decisive, and assertive;
which allows me to handle decisions and obstacles with confidence and direction.
On the other hand, active approach and optimism keep me adaptable and solution-oriented.
`

const hobbies = `
Outside of work, I’m passionate about staying active.

Running, tennis, motorcycling, and snowboarding are some of my favorite hobbies,
providing a sense of adventure and balance in life. These activities inspire me to maintain focus and enthusiasm during work.
`

const About = ({ className }: Props) => {
  return (
    <Tile className="relative">
      <ParallaxBanner
        layers={[{ image: `${composeFileFolderPath(FileTypes.IMAGE_ABOUT)}${IMAGE_HOME_BG_NAME}`, speed: -20 }]}
        className="max-h-40 md:max-h-50 lg:max-h-60 xl:max-h-70 aspect-[2/1] md:aspect-[3/1] lg:aspect-[4/1] rounded-2xl object-cover mt-3"
      >
        <div className="shieldBadgeIcon absolute right-1 md:right-5 top-1 md:top-5 bg-neutral-800/70 p-2 rounded-full">
          <UserShieldSvg className={clsx('svgIcon', 'w-7 md:w-8 lg:w-9 h-7 md:h-8 lg-h-9')} />
        </div>
      </ParallaxBanner>

      <div className="grid grid-cols-3 mt-10">
        <div className="col-span-3 md:col-span-2">
          <div className={clsx(className, 'text-lg, font-exo')}>
            <div className="text-normal font-sourcecode">
              <div className="first-letter:font-museo first-letter:float-left first-letter:mr-3 first-letter:pt-2 first-letter:text-5xl first-letter:text-blue-800 first-letter:font-bold pb-8">
                {workXp}
              </div>
              <div className="first-letter:font-museo first-letter:float-left first-letter:mr-3 first-letter:pt-2 first-letter:text-5xl first-letter:text-blue-800 first-letter:font-bold pb-8">
                {personalityTraits}
              </div>
              {
                // * disable hobbies section for time being
                // eslint-disable-next-line no-constant-binary-expression
                false && (
                  <div className="first-letter:font-museo first-letter:float-left first-letter:mr-3 first-letter:pt-2 first-letter:text-5xl first-letter:text-blue-800 first-letter:font-bold">
                    {hobbies}
                  </div>
                )
              }
            </div>
          </div>
        </div>
        <div className="col-span-3 md:col-span-1 mt-4 mb-8 md:mt-0 md:mb-14 ml-0 md:ml-8">
          <Carousel />
        </div>
      </div>
    </Tile>
  );
};

export default About;
