import { createApi } from '@reduxjs/toolkit/query/react';
import type {
  ApiResponseWrapper,
  GenericResponseResult
} from '../../types/common.model';
import type {
  CreateTool,
  CreateToolGroup,
  Tool,
  ToolGroup,
  UpdateTool,
  UpdateToolGroup
} from '../../types/tools.model';
import { baseQuery } from './baseClient';

export const toolsApi = createApi({
  reducerPath: 'toolsApi',
  baseQuery: baseQuery({ baseUrl: 'tools' }),
  tagTypes: ['Tools', 'ToolGroups'],
  endpoints: (builder) => ({
    /* TOOLS */
    getTools: builder.query<ApiResponseWrapper<Tool[]>, null>({
      query: () => ({ url: '/items', method: 'GET' }),
      providesTags: ['Tools']
    }),
    getToolById: builder.query<ApiResponseWrapper<Tool>, string>({
      query: (id) => ({ url: `/item/${id}`, method: 'GET' }),
      providesTags: ['Tools']
    }),
    createTool: builder.mutation<ApiResponseWrapper<Tool>, CreateTool>({
      query: (body) => ({ url: '/item', method: 'POST', body }),
      invalidatesTags: ['Tools']
    }),
    updateTool: builder.mutation<
      ApiResponseWrapper<Tool>,
      { id: string; body: UpdateTool }
    >({
      query: ({ id, body }) => ({ url: `/item/${id}`, method: 'PATCH', body }),
      invalidatesTags: ['Tools']
    }),
    deleteTool: builder.mutation<
      ApiResponseWrapper<GenericResponseResult>,
      string
    >({
      query: (id) => ({ url: `/item/${id}`, method: 'DELETE' }),
      invalidatesTags: ['Tools']
    }),

    /* TOOL GROUPS */
    getToolGroups: builder.query<ApiResponseWrapper<ToolGroup[]>, null>({
      query: () => ({ url: '/groups', method: 'GET' }),
      providesTags: ['ToolGroups']
    }),
    getToolGroupById: builder.query<ApiResponseWrapper<ToolGroup>, string>({
      query: (id) => ({ url: `/group/${id}`, method: 'GET' }),
      providesTags: ['ToolGroups']
    }),
    createToolGroup: builder.mutation<
      ApiResponseWrapper<ToolGroup>,
      CreateToolGroup
    >({
      query: (body) => ({ url: '/group', method: 'POST', body }),
      invalidatesTags: ['ToolGroups']
    }),
    updateToolGroup: builder.mutation<
      ApiResponseWrapper<ToolGroup>,
      { id: string; body: UpdateToolGroup }
    >({
      query: ({ id, body }) => ({ url: `/group/${id}`, method: 'PATCH', body }),
      invalidatesTags: ['ToolGroups']
    }),
    deleteToolGroup: builder.mutation<
      ApiResponseWrapper<GenericResponseResult>,
      string
    >({
      query: (id) => ({ url: `/group/${id}`, method: 'DELETE' }),
      invalidatesTags: ['ToolGroups']
    })
  })
});

export const {
  useGetToolsQuery,
  useGetToolByIdQuery,
  useCreateToolMutation,
  useUpdateToolMutation,
  useDeleteToolMutation,
  useGetToolGroupsQuery,
  useGetToolGroupByIdQuery,
  useCreateToolGroupMutation,
  useUpdateToolGroupMutation,
  useDeleteToolGroupMutation
} = toolsApi;
