import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { useDispatch, useSelector } from 'react-redux';
import { Environment, environmentVariables } from '../constants/environment';
import { toolsApi } from './apis/tools.api';
import { projectsApi } from './apis/projects.api';
import { siteItemsApi } from './apis/siteItems.api';
import { skillsApi } from './apis/skills.api';
import { usersApi } from './apis/users.api';
import { toolsReducer } from './slices/tools.slice';
import { projectsReducer } from './slices/projects.slice';
import { siteItemsReducer } from './slices/siteItems.slice';
import { skillsReducer } from './slices/skills.slice';
import { usersReducer } from './slices/users.slice';
import { layoutReducer } from './slices/layout.slice';

export const store = configureStore({
  reducer: {
    users: usersReducer,
    tools: toolsReducer,
    skills: skillsReducer,
    projects: projectsReducer,
    siteItems: siteItemsReducer,
    layout: layoutReducer,
    [toolsApi.reducerPath]: toolsApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
    [skillsApi.reducerPath]: skillsApi.reducer,
    [projectsApi.reducerPath]: projectsApi.reducer,
    [siteItemsApi.reducerPath]: siteItemsApi.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(toolsApi.middleware)
      .concat(usersApi.middleware)
      .concat(skillsApi.middleware)
      .concat(projectsApi.middleware)
      .concat(siteItemsApi.middleware),
  devTools: environmentVariables.MODE !== Environment.PRODUCTION
});

// INFO - refetchOnFocus/refetchOnReconnect behaviors
setupListeners(store.dispatch);

// Infer types
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

// Redux hooks with inferred types (replace useDispatch and useSelector)
export const useAppDispatch = useDispatch.withTypes<AppDispatch>();
export const useAppSelector = useSelector.withTypes<RootState>();
