import clsx from 'clsx';
import { format } from "date-fns";

type Props = {
  className?: string;
}

const Footer = ({className}: Props) => {
  return (
    <div className={clsx(className, 'flex flex-row justify-end pt-3 pb-0 md:pb-6')}>
      <div className="text-stone-400 text-sm font-exo">{`© Bart Fojcik ${format(new Date(), 'yyyy')}`}</div>
    </div>
  )
}

export default Footer