import clsx from 'clsx';
import sample from 'lodash/sample';
import { useEffect, useMemo } from 'react';
import SVG from 'react-inlinesvg';
import { ParallaxBanner } from 'react-scroll-parallax';
import { v4 } from 'uuid';
import MicroscopeSvg from '../../assets/microscope.svg?react';
import Loader from '../../components/Loader/Loader';
import Tile from '../../components/Tile/Tile';
import { composeFileFolderPath, FileTypes, IMAGE_SITE_ITEMS_BG_NAME } from '../../constants/files';
import { useGetSiteItemsQuery } from '../../redux/apis/siteItems.api';
import { layoutActions } from '../../redux/slices/layout.slice';
import { useAppDispatch } from '../../redux/store';
import { ToastTypes } from '../../types/toast.model';
import './SiteItems.css';
import { BreakpointsResult, useBreakpoint } from '../../hooks/useBreakpoints';

export interface Props {
  className?: string;
}

const animationClasses = [
  'dangleAnimation',
  'wiggleAnimation',
  'fadeInOutAnimation',
  'pulseAnimation',
  'flipAnimation',
];


const SiteItems = ({ className }: Props) => {

  const dispatch = useAppDispatch();

  const { data: siteItems, isLoading: itemsLoading, error: itemsError } = useGetSiteItemsQuery(null, {});

  const breaks: BreakpointsResult = useBreakpoint();

  const iconSize: number = useMemo(() => {
    if (breaks.isMdPlus) return 36;
    if (breaks.isSmMinus) return 32;
    return 16;
  }, [breaks]);

  useEffect(() => {
    if (itemsError) {
      console.error('siteItemsError: ', itemsError);
      dispatch(layoutActions.addToast({
        id: v4(),
        type: ToastTypes.ERROR,
        message: 'Error fetching Site Items'
      }))
    }
  }, [itemsError, dispatch])

  return (
    <div className={clsx(className)}>
      {
        !itemsLoading ? (
          <Tile>
            <ParallaxBanner
              layers={[{ image: `${composeFileFolderPath(FileTypes.IMAGE_SITE_LIB)}${IMAGE_SITE_ITEMS_BG_NAME}`, speed: -20 }]}
              className="max-h-40 md:max-h-50 lg:max-h-60 xl:max-h-70 aspect-[2/1] md:aspect-[3/1] lg:aspect-[4/1] rounded-2xl object-cover mt-3"
            >
              <div className="bgTitleIcon absolute right-1 md:right-5 top-1 md:top-5 bg-neutral-800/70 p-2 rounded-full">
                <MicroscopeSvg className={clsx('w-7 md:w-8 lg:w-9 h-7 md:h-8 lg-h-9')} />
              </div>
            </ParallaxBanner>

            <div className="">
              <div className="font-museo text-xl md:text-2xl pt-10 pb-10 w-full text-center">
                List of technologies & libraries used to create this page
              </div>

              <div className="border border-neutral-700 border-dashed rounded-lg p-3">

                <div
                  id="cont"
                  className="grid grid-cols-1 md:grid-cols-2 gap-3 pt-3"
                >
                  {siteItems?.response.map((siteItem, idx) => {
                    return (
                      <div
                        key={`site-lib-item-${idx}`}
                        className={clsx("translateXAnimation blurShortAnimation flex flex-row items-center pb-3")}
                      >
                        <SVG
                          src={`${composeFileFolderPath(FileTypes.IMAGE_SITE_LIB)}${siteItem.imageName}`}
                          width="auto"
                          height={iconSize}
                          title={`${siteItem.name} Icon`}
                          className={clsx(
                            sample(animationClasses),
                            'w-9 md:w-9  h-auto mr-4'
                          )}
                        />
                        <div className="blurCombinedAnimation font-exo">{siteItem.name}</div>
                      </div>
                    )
                  }
                  )}
                </div>
              </div>
            </div>
          </Tile>
        ) : (
          <Loader />
        )
      }
    </div>
  );
};

export default SiteItems;
