import clsx from 'clsx';
import { useEffect } from 'react';
import { v4 } from 'uuid';
import Loader from '../../components/Loader/Loader';
import { useGetToolGroupsQuery, useGetToolsQuery } from '../../redux/apis/tools.api';
import { layoutActions } from '../../redux/slices/layout.slice';
import { useAppDispatch } from '../../redux/store';
import { ToastTypes } from '../../types/toast.model';
import ToolItem from './ToolItem';

export interface Props {
  className?: string;
}

const Tools = ({ className }: Props) => {

  const dispatch = useAppDispatch();

  const { data: toolGroups, isLoading: groupsLoading, error: groupsError } = useGetToolGroupsQuery(null, {});
  const { data: toolItems, isLoading: itemsLoading, error: itemsError } = useGetToolsQuery(null, {});

  useEffect(() => {
    if (groupsError) {
      console.error('groupsError: ', groupsError);
      dispatch(layoutActions.addToast({
        id: v4(),
        type: ToastTypes.ERROR,
        message: 'Error fetching Tool Groups'
      }))
    }

    if (itemsError) {
      console.error('itemsError: ', itemsError);
      dispatch(layoutActions.addToast({
        id: v4(),
        type: ToastTypes.ERROR,
        message: 'Error fetching Tool Items'
      }))
    }
  }, [groupsError, itemsError, dispatch])

  return (
    <div className={clsx(className)}>
      {
        !(groupsLoading || itemsLoading) ? (
          toolGroups?.response.map((group, idx) => (
            <ToolItem key={`tool-item-${idx}`} group={group} tools={toolItems?.response ?? []} tileIdx={idx} />
          ))
        ) : (
          <Loader />
        )
      }
    </div>
  );
};

export default Tools;
