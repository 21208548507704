import { SkillType } from '../types/skills.model';

export const skillTypesLabelsMapping: (skillType: SkillType) => string = (skillType: SkillType) => {
  switch (skillType) {
    case SkillType.FE_FRAMEWORK:
      return 'Frameworks';
    case SkillType.FE_STYLES:
      return 'Styling';
    case SkillType.FE_TESTS:
      return 'Testing';
    case SkillType.FE_LIBRARIES:
      return 'Libraries';
    case SkillType.BE_PYTHON:
      return 'Python';
    case SkillType.BE_JS:
      return 'JavaScript';
    case SkillType.BE_JAVA:
      return 'Java';
    case SkillType.DB:
      return 'Databases';
    case SkillType.CLOUD:
      return 'Cloud';
    case SkillType.EVENT:
      return 'Events & Brokers';
    case SkillType.CICD:
      return 'CI/CD';
    case SkillType.ANAYLTICS:
      return 'Contenerization & Analytics';
    default:
      throw new Error(`Unknown skill type: ${skillType}`);
  }
}
