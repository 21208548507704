import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { Skill, SkillGroup } from '../../types/skills.model';
import type { RootState } from '../store';

type SkillsState = {
  mutationSkill: Skill | null;
  mutationSkillGroup: SkillGroup | null;
};

const initialState: SkillsState = {
  mutationSkill: null,
  mutationSkillGroup: null
};

const skillsSlice = createSlice({
  name: 'skills',
  initialState,
  reducers: {
    setMutationSkill: (
      state: SkillsState,
      action: PayloadAction<Skill | null>
    ) => {
      state.mutationSkill = action.payload;
    },
    setMutationSkillGroup: (
      state: SkillsState,
      action: PayloadAction<SkillGroup | null>
    ) => {
      state.mutationSkillGroup = action.payload;
    }
  }
});

export const skillsActions = {
  setMutationSkill: skillsSlice.actions.setMutationSkill,
  setMutationSkillGroup: skillsSlice.actions.setMutationSkillGroup
};

export const skillsSelectors = {
  selectMutationSkill: (state: RootState) => state.skills.mutationSkill,
  selectMutationSkillGroup: (state: RootState) =>
    state.skills.mutationSkillGroup
};

export const skillsReducer = skillsSlice.reducer;
