import clsx from 'clsx';
import ChevronSvg from '../../assets/chevron-right-dot.svg?react';

type Props = {
  items: string[];
  className?: string;
}

const ItemList = ({ items, className }: Props) => {
  return (
    <div className={clsx(className)}>
      {
        (items ?? []).map((feat, idx, items) => (
          <div className={clsx({'pb-3': idx !== items.length - 1}, "flex flex-row justify-start items-center")} key={`project-desc-feat-${idx}`}>
            <ChevronSvg className='mr-2' />
            <div key={`project-desc-feat-${idx}`}>{feat}</div>
          </div>
        ))
      }
    </div>
  )

};

export default ItemList