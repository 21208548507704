import { createApi } from '@reduxjs/toolkit/query/react';
import type {
  ApiResponseWrapper,
  GenericResponseResult
} from '../../types/common.model';
import type {
  CreateSiteItem,
  SiteItem,
  UpdateSiteItem
} from '../../types/siteItems.model';
import { baseQuery } from './baseClient';

export const siteItemsApi = createApi({
  reducerPath: 'siteItemsApi',
  baseQuery: baseQuery({ baseUrl: 'site_items' }),
  tagTypes: ['SiteItems'],
  endpoints: (builder) => ({
    getSiteItems: builder.query<ApiResponseWrapper<SiteItem[]>, null>({
      query: () => ({ url: '', method: 'GET' }),
      providesTags: ['SiteItems']
    }),
    getSiteItemById: builder.query<ApiResponseWrapper<SiteItem>, string>({
      query: (id) => ({ url: `/${id}`, method: 'GET' }),
      providesTags: ['SiteItems']
    }),
    createSiteItem: builder.mutation<
      ApiResponseWrapper<SiteItem>,
      CreateSiteItem
    >({
      query: (body) => ({ url: '', method: 'POST', body }),
      invalidatesTags: ['SiteItems']
    }),
    updateSiteItem: builder.mutation<
      ApiResponseWrapper<SiteItem>,
      { id: string; body: UpdateSiteItem }
    >({
      query: ({ id, body }) => ({ url: `/${id}`, method: 'PATCH', body }),
      invalidatesTags: ['SiteItems']
    }),
    deleteSiteItem: builder.mutation<
      ApiResponseWrapper<GenericResponseResult>,
      string
    >({
      query: (id) => ({ url: `/${id}`, method: 'DELETE' }),
      invalidatesTags: ['SiteItems']
    })
  })
});

export const {
  useGetSiteItemsQuery,
  useGetSiteItemByIdQuery,
  useCreateSiteItemMutation,
  useUpdateSiteItemMutation,
  useDeleteSiteItemMutation
} = siteItemsApi;
