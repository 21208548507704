import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { Tool, ToolGroup } from '../../types/tools.model';
import type { RootState } from '../store';

type ToolsState = {
  mutationTool: Tool | null;
  mutationToolGroup: ToolGroup | null;
};

const initialState: ToolsState = {
  mutationTool: null,
  mutationToolGroup: null
};

const toolsSlice = createSlice({
  name: 'tools',
  initialState,
  reducers: {
    setMutationTool: (
      state: ToolsState,
      action: PayloadAction<Tool | null>
    ) => {
      state.mutationTool = action.payload;
    },
    setMutationToolGroup: (
      state: ToolsState,
      action: PayloadAction<ToolGroup | null>
    ) => {
      state.mutationToolGroup = action.payload;
    }
  }
});

export const toolsActions = {
  setMutationTool: toolsSlice.actions.setMutationTool,
  setMutationToolGroup: toolsSlice.actions.setMutationToolGroup
};

export const toolsSelectors = {
  selectMutationTool: (state: RootState) => state.tools.mutationTool,
  selectMutationToolGroup: (state: RootState) =>
    state.tools.mutationToolGroup
};

export const toolsReducer = toolsSlice.reducer;
