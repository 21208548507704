import throttle from 'lodash/throttle';
import { useEffect, useState } from 'react';
import { Breakpoints, breakpointValues } from '../constants/breakpoints';

export interface BreakpointsResult {
  name: string;

  isXxl: boolean;
  isXxlMinus: boolean;
  isXxlPlus: boolean;

  isXl: boolean;
  isXlMinus: boolean;
  isXlPlus: boolean;

  isLg: boolean;
  isLgMinus: boolean;
  isLgPlus: boolean;

  isMd: boolean;
  isMdMinus: boolean;
  isMdPlus: boolean;

  isSm: boolean;
  isSmMinus: boolean;
  isSmPlus: boolean;

  isXs: boolean;
  isXsMinus: boolean;
  isXsPlus: boolean;
}

export const useBreakpoint: () => BreakpointsResult = () => {
  const [breakpoint, setBreakpoint] = useState<Breakpoints>(getBreakpoint());

  const resizeEvent = () => {
    setBreakpoint(getBreakpoint());
  };

  useEffect(() => {
    window.addEventListener('resize', throttle(resizeEvent, 150));
    return () => {
      window.removeEventListener('resize', throttle(resizeEvent, 150));
    };
  }, []);

  return {
    name: breakpoint ?? Breakpoints.XXL,

    isXxl: breakpoint === Breakpoints.XXL,
    isXxlMinus: true,
    isXxlPlus: breakpoint === Breakpoints.XXL,

    isXl: breakpoint === Breakpoints.XL,
    isXlMinus: [Breakpoints.XL, Breakpoints.LG, Breakpoints.MD, Breakpoints.SM, Breakpoints.XS].includes(breakpoint!),
    isXlPlus: [Breakpoints.XL, Breakpoints.XXL].includes(breakpoint!),

    isLg: breakpoint === Breakpoints.LG,
    isLgMinus: [Breakpoints.LG, Breakpoints.MD, Breakpoints.SM, Breakpoints.XS].includes(breakpoint!),
    isLgPlus: [Breakpoints.LG, Breakpoints.XL, Breakpoints.XXL].includes(breakpoint!),

    isMd: breakpoint === Breakpoints.MD,
    isMdMinus: [Breakpoints.MD, Breakpoints.SM, Breakpoints.XS].includes(breakpoint!),
    isMdPlus: [Breakpoints.MD, Breakpoints.LG, Breakpoints.XL, Breakpoints.XXL].includes(breakpoint!),

    isSm: breakpoint === Breakpoints.SM,
    isSmMinus: [Breakpoints.SM, Breakpoints.XS].includes(breakpoint!),
    isSmPlus: [Breakpoints.SM, Breakpoints.MD, Breakpoints.LG, Breakpoints.XL, Breakpoints.XXL].includes(breakpoint!),

    isXs: breakpoint === Breakpoints.XS,
    isXsMinus: breakpoint === Breakpoints.XS,
    isXsPlus: true,
  };
};

const getBreakpoint: () => Breakpoints = () => {
  let breakpoint = null;
  if (typeof window !== 'undefined') {
    if (window.matchMedia(`(min-width: ${breakpointValues[Breakpoints.XXL]}px)`).matches) {
      breakpoint = Breakpoints.XXL;
    } else if (window.matchMedia(`(min-width: ${breakpointValues[Breakpoints.XL]}px)`).matches) {
      breakpoint = Breakpoints.XL;
    } else if (window.matchMedia(`(min-width: ${breakpointValues[Breakpoints.LG]}px)`).matches) {
      breakpoint = Breakpoints.LG;
    } else if (window.matchMedia(`(min-width: ${breakpointValues[Breakpoints.MD]}px)`).matches) {
      breakpoint = Breakpoints.MD;
    } else if (window.matchMedia(`(min-width: ${breakpointValues[Breakpoints.SM]}px)`).matches) {
      breakpoint = Breakpoints.SM;
    } else if (window.matchMedia(`(min-width: ${breakpointValues[Breakpoints.XS]}px)`).matches) {
      breakpoint = Breakpoints.XS;
    }
  }
  return breakpoint ?? Breakpoints.XXL;
};
