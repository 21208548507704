import { ParallaxBanner } from 'react-scroll-parallax';
import Tile from '../../components/Tile/Tile';
import { Project } from '../../types/projects.model';
import ItemList from '../../components/ItemList/ItemList';
import { composeFileFolderPath, FileTypes } from '../../constants/files';
import { useVisible } from '../../hooks/useVisible';
import { RefObject, useRef } from 'react';
import clsx from 'clsx';
import './ProjectItem.css';

type Props = {
  project: Project,
  tileIdx: number,
  noOfProjects: number,
  className?: string
}

const ProjectItem = ({className, project, tileIdx, noOfProjects}: Props) => {

  const ref: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
  const refVisible: boolean = useVisible(ref);

  return (
    <div className={clsx(className, {'containerVisible': refVisible})} ref={ref}>
      <Tile paddingClass={clsx({'mb-10': tileIdx !== noOfProjects - 1}, 'px-4 sm:px-8 md:px-12 lg:px-20 py-8')} appearVisible={true}>
        <ParallaxBanner
          layers={[{ image: `${composeFileFolderPath(FileTypes.IMAGE_PROJECT)}${project.backgroundImageName}`, speed: -20 }]}
          className="max-h-40 md:max-h-50 lg:max-h-60 xl:max-h-70 aspect-[2/1] md:aspect-[3/1] lg:aspect-[4/1] rounded-2xl object-cover"
        >
          <div className="absolute inset-0 flex items-center justify-center">
            <div className="text-3xl md:text-4xl font-museo font-semibold text-stone-100 rounded-2xl p-4 bg-neutral-800/80">{project.alias}</div>
          </div>
        </ParallaxBanner>
        <div className='pt-6 divide-y divide-neutral-800 font-sourcecode'>
          <div className="flex flex-row justify-center items-center pb-6">
            <img className='topicIcon max-w-12 mr-2' src={`${composeFileFolderPath(FileTypes.IMAGE_PROJECT)}${project.imageName}`} alt="Project Logo" />
            <div className="text-xl md:text-2xl font-bold text-center max-w-96 font-exo pl-4">{project.description.topic}</div>
          </div>
          <div className="py-4">
            <div className="py-2 font-semibold text-lg">Features:</div>
            <ItemList items={project.description.features} />
          </div>
          <div className="py-4">
            <div className="pb-2 font-semibold text-lg">Infrastructure:</div>
            <ItemList items={project.description.infrastructure} />
          </div>
          <div className="py-4">
            <div className="pb-2 font-semibold text-lg">Work methodology:</div>
            <ItemList items={[project.description.workMethodology]} />
          </div>
          <div className="pt-4">
            <div className="pb-2 font-semibold text-lg">Technologies:</div>
            <ItemList items={project.description.technologies} />
          </div>
        </div>
      </Tile>
    </div>
  )
}

export default ProjectItem