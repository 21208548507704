export enum Breakpoints {
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
  XL = 'xl',
  XXL = '2xl'
}

export const breakpointValues: Record<Breakpoints, number> = {
  [Breakpoints.XS]: 0,
  [Breakpoints.SM]: 640,
  [Breakpoints.MD]: 768,
  [Breakpoints.LG]: 1024,
  [Breakpoints.XL]: 1280,
  [Breakpoints.XXL]: 1536,
}
