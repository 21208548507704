import clsx from 'clsx';
import throttle from 'lodash/throttle';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { routeLabels, RouteNames } from '../../constants/routes';
import { layoutSelectors } from '../../redux/slices/layout.slice';
import { useAppSelector } from '../../redux/store';
import Hamburger from '../Hamburger/Hamburger';
import Logo from '../Logo/Logo';
import './Header.css';

interface Props {
  className?: string;
}

const Header = ({ className }: Props) => {
  const [isNavbarSticky, setIsNavbarSticky] = useState(false);
  const hamburgerActive: boolean = useAppSelector(
    layoutSelectors.selectHamburgerActive
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const scrollThrottle = throttle((_event: Event) => {
    if (document.documentElement.scrollTop > 20) {
      setIsNavbarSticky(true);
    } else {
      setIsNavbarSticky(false);
    }
  }, 50);

  useEffect(() => {
    window.addEventListener('scroll', scrollThrottle);
    return () => {
      window.removeEventListener('scroll', scrollThrottle);
    };
  }, [scrollThrottle]);

  return (
    <nav
      className={clsx(
        className,
        {
          'bg-zinc-950/95 text-zinc-300 shadow-sm shadow-zinc-900':
            isNavbarSticky || hamburgerActive
        },
        'navbarContainer fixed top-0 left-0 w-full py-4 px-5 transition-all'
      )}
    >
      <div className="flex flex-row">
        <div className="grow-0">
          <Logo />
        </div>
        <div className="grow font-museo font-semibold">
          {/* DESKTOP */}
          <ul
            role="menubar"
            tabIndex={0}
            className="nav-links md:flex hidden justify-center"
          >
            <Link
              role="menuitem"
              className={clsx({'text-xl': !isNavbarSticky, 'text-lg': isNavbarSticky}, "hover:text-blue-500 hover:scale-125 transition-all pr-4")}
              to={RouteNames.ABOUT}
            >
              <div>{routeLabels[RouteNames.ABOUT]}</div>
            </Link>
            <Link
              role="menuitem"
              className={clsx({'text-xl': !isNavbarSticky, 'text-lg': isNavbarSticky}, "hover:text-blue-500 hover:scale-125 transition-all pr-4")}
              to={RouteNames.PROJECTS}
            >
              <div>{routeLabels[RouteNames.PROJECTS]}</div>
            </Link>
            <Link
              role="menuitem"
              className={clsx({'text-xl': !isNavbarSticky, 'text-lg': isNavbarSticky}, "hover:text-blue-500 hover:scale-125 transition-all pr-4")}
              to={RouteNames.SKILLS}
            >
              <div>{routeLabels[RouteNames.SKILLS]}</div>
            </Link>
            <Link
              role="menuitem"
              className={clsx({'text-xl': !isNavbarSticky, 'text-lg': isNavbarSticky}, "hover:text-blue-500 hover:scale-125 transition-all pr-4")}
              to={RouteNames.TOOLS}
            >
              <div>{routeLabels[RouteNames.TOOLS]}</div>
            </Link>
            <Link
              role="menuitem"
              className={clsx({'text-xl': !isNavbarSticky, 'text-lg': isNavbarSticky}, "hover:text-blue-500 hover:scale-125 transition-all")}
              to={RouteNames.SITE_ITEMS}
            >
              <div>{routeLabels[RouteNames.SITE_ITEMS]}</div>
            </Link>
          </ul>

          {/* MOBILE */}
          <Hamburger className="block md:hidden" />
        </div>
      </div>
    </nav>
  );
};

export default Header;
