import clsx from 'clsx';
import isNil from 'lodash/isNil';
import { useEffect, useState } from 'react';
import SVG from 'react-inlinesvg';
import { v4 } from 'uuid';
import Loader from '../../components/Loader/Loader';
import Tile from '../../components/Tile/Tile';
import { composeFileFolderPath, FileTypes } from '../../constants/files';
import { iconStylingNameMapping, StylingType } from '../../constants/iconStyling';
import { useGetSkillGroupsQuery, useGetSkillsQuery } from '../../redux/apis/skills.api';
import { layoutActions } from '../../redux/slices/layout.slice';
import { useAppDispatch } from '../../redux/store';
import { SkillGroup } from '../../types/skills.model';
import { ToastTypes } from '../../types/toast.model';
import SkillItem from './SkillItem';
import './Skills.css';

export interface Props {
  className?: string;
}

export interface SkillsTabState {
  id: string,
  name: string,
  active: boolean
}

const Skills = ({ className }: Props) => {

  const dispatch = useAppDispatch();
  const [tabGroups, setTabGroups] = useState<(SkillsTabState & SkillGroup)[]>([]);

  const { data: skillGroups, isLoading: groupsLoading, error: groupsError } = useGetSkillGroupsQuery(null, {});
  const { data: skillItems, isLoading: itemsLoading, error: itemsError } = useGetSkillsQuery(null, {});

  useEffect(() => {
    if (!isNil(skillGroups) && !isNil(skillItems)) {
      const tabGroups = skillGroups.response.map((group, idx) => ({
        ...group,
        active: idx === 0,
      }));
      setTabGroups(tabGroups);
    }
  }, [skillGroups, skillItems])

  const setActiveTab = (tabIdx: number) => {
    const newTabs = tabGroups.map((tab, idx) => ({
      ...tab,
      active: idx === tabIdx,
    }));
    setTabGroups(newTabs);
  }

  useEffect(() => {
    if (groupsError) {
      console.error('groupsError: ', groupsError);
      dispatch(layoutActions.addToast({
        id: v4(),
        type: ToastTypes.ERROR,
        message: 'Error fetching Skill Groups'
      }))
    }

    if (itemsError) {
      console.error('itemsError: ', itemsError);
      dispatch(layoutActions.addToast({
        id: v4(),
        type: ToastTypes.ERROR,
        message: 'Error fetching Skill Items'
      }))
    }
  }, [groupsError, itemsError, dispatch])

  return (
    <div className={clsx(className)}>
      {
        !(groupsLoading || itemsLoading) ? (
          // TAB TRIGGERS
          <div className="">
            <Tile
              className={clsx('flex flex-row justify-center items-center')}
              paddingClass='px-4 sm:px-8 py-2 mb-8'
            >
              {
                tabGroups.map((tabGroup, idx) => (
                  <div
                    key={`skill-tab-trigger-${idx}`}
                    className={clsx('groupContainer mr-0 sm:mr-6 last:mr-0 p-3 hover:text-blue-600 hover:scale-125 transition-all cursor-pointer text-xl font-museo flex flex-row items-center', tabGroup.active ? 'active' : '')}
                    onClick={() => setActiveTab(idx)}
                  >
                    <SVG
                      src={`${composeFileFolderPath(FileTypes.IMAGE_SKILL_GROUP)}${tabGroup.imageName}`}
                      width="auto"
                      height={32}
                      title={`${tabGroup.name} Icon`}
                      className={clsx(
                        'groupIcon h-8 pr-2',
                        tabGroup.active ? 'iconActive' : '',
                        {
                          'pathStrokeIcon': iconStylingNameMapping[tabGroup.imageName] === StylingType.PATH_STROKE,
                          'pathFillIcon': iconStylingNameMapping[tabGroup.imageName] === StylingType.PATH_FILL,
                          'topFillIcon': iconStylingNameMapping[tabGroup.imageName] === StylingType.TOP_FILL,
                        })
                      }
                    />
                    <div className='hidden sm:inline-block'>{tabGroup.name}</div>
                  </div>
                ))
              }
            </Tile>

            {/* TAB CONTENT */}
            <div className="">
              {
                tabGroups.map((group: SkillsTabState & SkillGroup, idx: number) => (
                  <div
                    key={`skill-tab-content-${idx}`}
                    className={clsx(group.active ? 'block' : 'hidden')}
                  >
                    <SkillItem group={group} skillItems={skillItems?.response ?? []} />
                  </div>
                ))
              }
            </div>
          </div>
        ) : (
          <Loader />
        )
      }
    </div>
  );
};

export default Skills;
