import clsx from 'clsx';
import { RefObject, useRef } from 'react';
import { useVisible } from '../../hooks/useVisible';
import './Tile.css';

export interface Props {
  children: React.ReactNode;
  className?: string;
  bgColorClass?: string;
  paddingClass?: string;
  appearVisible?: boolean;
}

export const defaultTileBgColorClass: string = 'bg-gradient-to-br from-neutral-800/80 from-0% via-neutral-900/80 via-50% to-neutral-950/80 to-100%';
export const defaultTileBorderClass: string = 'rounded-lg border border-neutral-800';
export const defaultTilePaddingClass: string = 'px-4 py-3 sm:px-8 sm:py-6';

const Tile = ({ children, className, bgColorClass, paddingClass, appearVisible = false }: Props) => {

  const ref: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
  const refVisible: boolean = useVisible(ref);

  return (
    <div
      className={
        clsx(
          className,
          {'tileVisible' : !appearVisible || (appearVisible && refVisible)},
          ` ${paddingClass ?? defaultTilePaddingClass}
            ${defaultTileBorderClass}
            text-neutral-200
            ${bgColorClass ?? defaultTileBgColorClass}
            transition-shadow
          `,
        )
      }
      ref={ref}
    >
      {children}
    </div>
  );
}

export default Tile;
