import clsx from 'clsx';
import uniq from 'lodash/uniq';
import { RefObject, useMemo, useRef } from 'react';
import SVG from 'react-inlinesvg';
import { ParallaxBanner } from 'react-scroll-parallax';
import Tile from '../../components/Tile/Tile';
import { composeFileFolderPath, FileTypes } from '../../constants/files';
import { iconStylingNameMapping, StylingType } from '../../constants/iconStyling';
import { BreakpointsResult, useBreakpoint } from '../../hooks/useBreakpoints';
import { useVisible } from '../../hooks/useVisible';
import { Tool, ToolGroup } from '../../types/tools.model';
import './ToolItem.css';

type Props = {
  group: ToolGroup,
  tools: Tool[],
  tileIdx: number,
  className?: string
}

const ToolItem = ({className, group, tools, tileIdx}: Props) => {

  const ref: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
  const refVisible: boolean = useVisible(ref);

  const noOgGroups: number = useMemo(() => uniq(tools.map(t => t.toolGroupId)).length, [tools])

  const breaks: BreakpointsResult = useBreakpoint();

  const badgeSize: number = useMemo(() => {
    if (breaks.isLgPlus) return 36;
    if (breaks.isMd) return 34;
    if (breaks.isSm) return 28;
    if (breaks.isXs) return 28;
    return 16;
  }, [breaks]);

  return (
    <div className={clsx(className, {'containerVisible': refVisible})} ref={ref}>
      <Tile paddingClass={clsx({'mb-10': tileIdx !== noOgGroups - 1}, 'project px-4 sm:px-8 md:px-12 lg:px-20 py-8')} appearVisible={true}>
        <ParallaxBanner
          layers={[{ image: `${composeFileFolderPath(FileTypes.IMAGE_TOOL_GROUP)}${group.backgroundImageName}`, speed: -20 }]}
          className="max-h-40 md:max-h-50 lg:max-h-60 xl:max-h-70 aspect-[2/1] md:aspect-[3/1] lg:aspect-[4/1] rounded-2xl object-cover"
        >
          <div className="absolute inset-0 flex items-center justify-center">
            <div className="text-3xl md:text-4xl font-museo font-semibold text-stone-100 rounded-2xl p-4 bg-neutral-800/90">{group.name}</div>
          </div>
          <div className="topicIcon absolute right-1 md:right-5 top-1 md:top-5 bg-neutral-800/90 p-2 rounded-full">
            <SVG
              src={`${composeFileFolderPath(FileTypes.IMAGE_TOOL_GROUP)}${group.imageName}`}
              width="auto"
              height={badgeSize}
              title={`${group.name} Icon`}
              className={clsx(
                {
                  'topFillIcon': iconStylingNameMapping[group.imageName] === StylingType.TOP_FILL,
                }
              )
              }
            />
          </div>
        </ParallaxBanner>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-y-5 pb-3 pt-3 mt-7 border border-neutral-700 border-dashed rounded-lg p-3">
          {
            tools.filter(t => t.toolGroupId === group.id) .map((tool, idx) => (
              <div key={`tool-item-${idx}`} className="flex flex-row items-center ">
                <img
                  className={clsx('w-9 md:w-9 mr-6', {'blurredAnimation': refVisible})}
                  src={`${composeFileFolderPath(FileTypes.IMAGE_TOOL_ITEM)}${tool.imageName}`}
                  alt='Tool Item Icon'
                />
                <div className="text-lg font-exo">{tool.name}</div>
              </div>
            ))
          }
        </div>
      </Tile>
    </div>
  )
}

export default ToolItem