import clsx from 'clsx';
import { motivationalList, wordColorMapping } from '../../constants/rollingTitle';
import './RollingTitle.css';

interface Props {
  className?: string;
  preTitle?: string;
}

const RollingTitle = ({ className, preTitle }: Props) => {
  return (
    <div className="flex md:flex-row md:justify-center flex-col">
      {preTitle && <h2 className='text-center'>{preTitle}</h2>}
      <h2 className={clsx('flex flex-row justify-center text-3xl sm:text-4xl overflow-y-hidden h-10', className)}>
        <div className='pl-0 sm:pl-16 md:pl-3'>Aim for</div>
        <ul className={clsx('roundAndRound', "w-44 sm:w-60 text-start")}>
          {
            motivationalList.map((word, index) => (
              <li
                key={index}
                style={{ color: wordColorMapping[word] }}
              >
                {word}
              </li>
              )
            )
          }
        </ul>
      </h2>
    </div>
  )
}

export default RollingTitle;