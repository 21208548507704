import clsx from 'clsx';
import './Loader.css';

type Props = {
  className?: string;
}

const Loader = ({ className }: Props) => {
  return (
    <div className={clsx(className, 'w-full h-full flex justify-center items-center')}>
      <div className="loader"/>
    </div>
  )
}

export default Loader
