import clsx from 'clsx';
import sortBy from 'lodash/sortBy';
import { useEffect } from 'react';
import { v4 } from 'uuid';
import Loader from '../../components/Loader/Loader';
import { useGetProjectsQuery } from '../../redux/apis/projects.api';
import { layoutActions } from '../../redux/slices/layout.slice';
import { useAppDispatch } from '../../redux/store';
import { Project } from '../../types/projects.model';
import { ToastTypes } from '../../types/toast.model';
import ProjectItem from './ProjectItem';

export interface Props {
  className?: string;
}

const Projects = ({ className }: Props) => {

  const dispatch = useAppDispatch();

  const { data: projects, isLoading: projectsLoading, error: projectsError } = useGetProjectsQuery(null, {});

  useEffect(() => {
    if (projectsError) {
      console.error('projectsError: ', projectsError);
      dispatch(layoutActions.addToast({
        id: v4(),
        type: ToastTypes.ERROR,
        message: 'Error fetching projects'
      }))
    }
  }, [projectsError, dispatch])

  return (
    <div className={clsx(className, 'w-full min-h-40')}>
      {
        !projectsLoading ? (
          sortBy((projects?.response ?? []), [p => p.priority])
            .map((project: Project, idx: number, items: Project[]) =>
              <ProjectItem project={project} key={`project-item-${idx}`} tileIdx={idx} noOfProjects={items.length} />)
        ) : (
            <Loader />
        )
      }
    </div>
  );
};

export default Projects;
