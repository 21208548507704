import { jwtDecode } from 'jwt-decode';
import { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { v4 } from 'uuid';
import Background from './components/Background/Background';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import Page from './components/Page/Page';
import RollingTitle from './components/RollingTitle/RollingTitle';
import Toaster from './components/Toaster/Toaster';
import { environmentVariables } from './constants/environment';
import { routeLabels, RouteNames } from './constants/routes';
import About from './pages/about/About.page';
import Home from './pages/home/Home.page';
import NotFound from './pages/not_found/NotFound.page';
import Projects from './pages/projects/Projects.page';
import SiteItems from './pages/site_items/SiteItems.page';
import Skills from './pages/skills/Skills.page';
import Tools from './pages/tools/Tools.page';
import { useLoginMutation } from './redux/apis/users.api';
import { layoutActions } from './redux/slices/layout.slice';
import { usersActions } from './redux/slices/users.slice';
import { useAppDispatch } from './redux/store';
import { StorageService } from './services/storage.service';
import { ToastTypes } from './types/toast.model';
import type { User } from './types/users.model';

let didInit = false;

function App() {

  const dispatch = useAppDispatch();

  const [loginMutation, {isLoading: loginLoading}] = useLoginMutation();

  useEffect(() => {
    const loginBaseUser = async () => {
      try {
        const loginResponse = await loginMutation({
          email: environmentVariables.VITE_API_BASE_USER_EMAIL,
          password: environmentVariables.VITE_API_BASE_USER_PASSWORD
        }).unwrap();

        StorageService.setAccessToken(loginResponse.response.accessToken);

        const decodedToken: User = jwtDecode(
          loginResponse.response.accessToken
        ) as User;
        dispatch(usersActions.setCurrentUser(decodedToken));
      } catch (error) {
        console.error('loginBaseUser error', error);
        dispatch(layoutActions.addToast({
          id: v4(),
          type: ToastTypes.ERROR,
          message: 'Error fetching User'
        }))
      }
    };
    if (!didInit) {
      didInit = true;
      loginBaseUser();
    }
  }, [dispatch, loginMutation]);

  return (
    <>
      {
        (
          <div>
            <div className="z-10 relative flex flex-col" style={{minHeight: '90vh'}}>
              <Header className='z-40' />

              <div className='z-30 pb-5'>
                <Routes>
                  <Route path={RouteNames.HOME} element={<Page key="page-home" loading={loginLoading} hideLoader={true} titleComponent={<RollingTitle preTitle="Hello, I'm Bart." />}><Home /></Page>} />
                  <Route path={RouteNames.ABOUT} element={<Page key="page-about" loading={loginLoading} title={routeLabels[RouteNames.ABOUT]}><About /></Page>} />
                  <Route path={RouteNames.PROJECTS} element={<Page key="page-projects" loading={loginLoading} title={routeLabels[RouteNames.PROJECTS]}><Projects /></Page>} />
                  <Route path={RouteNames.SKILLS} element={<Page key="page-skills" loading={loginLoading} title={routeLabels[RouteNames.SKILLS]}><Skills /></Page>} />
                  <Route path={RouteNames.TOOLS} element={<Page key="page-tools" loading={loginLoading} title={routeLabels[RouteNames.TOOLS]}><Tools /></Page>} />
                  <Route path={RouteNames.SITE_ITEMS} element={<Page key="page-site-items" loading={loginLoading} title={routeLabels[RouteNames.SITE_ITEMS]}><SiteItems /></Page>} />
                  <Route path="*" element={<Page key="page-not-found" loading={loginLoading} title={routeLabels[RouteNames.NOT_FOUND]}><NotFound /></Page>} />
                </Routes>
              </div>

              <div className="absolute bottom-0 h-5 w-full">
                <Footer />
              </div>
            </div>

            <Toaster />

            <Background className="z-0" />
          </div>
        )
      }
    </>
  );
}

export default App;
