import type { ISourceOptions } from '@tsparticles/engine';
import Particles, { initParticlesEngine } from '@tsparticles/react';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Colors from 'tailwindcss/colors';
import { loadFull } from 'tsparticles';
import type { PageDotColors, RouteNames } from '../../constants/routes';
import { routeBgMapping, routes } from '../../constants/routes';

const defaultOptions: ISourceOptions = {
  background: {
    color: {
      value: Colors.zinc[950]
    }
  },
  fpsLimit: 120,
  interactivity: {
    events: {
      onHover: {
        enable: true,
        mode: 'repulse'
      }
    },
    modes: {
      push: {
        quantity: 8
      },
      repulse: {
        distance: 200,
        duration: 1.1
      }
    }
  },
  particles: {
    color: {
      value: Colors.amber[400]
    },
    links: {
      color: Colors.amber[700],
      distance: 150,
      enable: true,
      opacity: 0.9,
      width: 1
    },
    move: {
      direction: 'none',
      enable: true,
      outModes: {
        default: 'bounce'
      },
      random: false,
      speed: 1.5,
      straight: false
    },
    number: {
      density: {
        enable: true
      },
      value: 120
    },
    opacity: {
      value: 0.5
    },
    shape: {
      type: 'circle'
    },
    size: {
      value: { min: 1, max: 3 }
    }
  },
  detectRetina: true
};

interface Props {
  className?: string;
}

const Background = ({ className }: Props) => {
  const [init, setInit] = useState<boolean>(false);
  const [bgOptions, setBgOptions] = useState<ISourceOptions>(defaultOptions);

  const location = useLocation();

  useEffect(() => {
    initParticlesEngine(async (engine) => {
      await loadFull(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);

  useEffect(() => {
    if (init) {
      const foundRoute: [string, RouteNames] | undefined = routes.find(
        (route) => location.pathname === route[1]
      );
      let chosenColors: PageDotColors;
      if (foundRoute) {
        chosenColors = routeBgMapping[foundRoute[1]];
      } else {
        chosenColors = {
          pointColor: defaultOptions.particles!.color!.value as string,
          linkColor: (defaultOptions.particles!.links! as { color:string }).color as string
        };
      }
      setBgOptions({
        ...bgOptions,
        particles: {
          ...bgOptions.particles,
          color: {
            ...(bgOptions.particles?.color ?? {}),
            value: chosenColors?.pointColor
          },
          links: {
            ...(bgOptions.particles?.links ?? {}),
            color: chosenColors?.linkColor
          }
        }
      });
    }
  }, [location, init]);

  return (
    <div className={clsx(className, 'dotsContainer w-full h-full')}>
      {init && <Particles id="tsparticles" options={bgOptions} />}
    </div>
  );
};

export default Background;
