import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { User } from '../../types/users.model';
import type { RootState } from '../store';

type UsersState = {
  currentUser: User | null;
  mutationUser: User | null;
};

const initialState: UsersState = {
  currentUser: null,
  mutationUser: null
};

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setCurrentUser: (state: UsersState, action: PayloadAction<User | null>) => {
      state.currentUser = action.payload;
    },
    setMutationUser: (
      state: UsersState,
      action: PayloadAction<User | null>
    ) => {
      state.mutationUser = action.payload;
    }
  }
});

export const usersActions = {
  setCurrentUser: usersSlice.actions.setCurrentUser,
  setMutationUser: usersSlice.actions.setMutationUser
};

export const usersSelectors = {
  selectCurrentUser: (state: RootState) => state.users.currentUser,
  selectMutationUser: (state: RootState) => state.users.mutationUser
};

export const usersReducer = usersSlice.reducer;
