import clsx from 'clsx';
import Tile from '../../components/Tile/Tile';
import Video from '../../components/Video/Video';

interface Props {
  className?: string;
}

const Home = ({ className }: Props) => {
  return (
    <div className={clsx(className, 'flex flex-col')}>
      <Video className='mb-20' />

      <Tile paddingClass='px-4 py-8 sm:px-16 md:px-18'>
        <div className='pb-6 text-center text-xl md:text-2xl font-museo'>I'm a programmer and designer</div>
        <div className="text-base md:text-lg divide-y divide-neutral-800 font-sourcecode">
          <div>
            <div className="pb-4">This site is a showcase of my career so far.</div>
            <div className="pb-6">It includes some of the projects I've worked on, the skills I've acquired, the tools I've used, and the sites I've built.</div>
          </div>
          <div className="pt-4 pb-0 font-sourcecode">I hope you'll enjoy your stay.</div>
        </div>
      </Tile>
    </div>
  );
};

export default Home;
