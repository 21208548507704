import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ParallaxProvider } from 'react-scroll-parallax';
import App from './App';
import { store } from './redux/store';
import './main.css';

ReactDOM.createRoot(document.getElementById('root')!).render(
  <ParallaxProvider>
    <BrowserRouter>
      <Provider store={store}>
        <App />
      </Provider>
    </BrowserRouter>
  </ParallaxProvider>
);

// TODO - consider adding dark and light mode via `prefers-color-scheme` - https://tailwindcss.com/docs/hover-focus-and-other-states#prefers-color-scheme / https://tailwindcss.com/docs/dark-mode
// TODO - consider adding forced colors via `forced-colors` - https://tailwindcss.com/docs/hover-focus-and-other-states#forced-colors-mode
// TODO - consider adding reduced motion via `prefers-reduced-motion` - https://tailwindcss.com/docs/hover-focus-and-other-states#prefers-reduced-motion
