import { RefObject, useEffect, useState } from 'react';

export const useVisible: (ref: RefObject<HTMLElement>) => boolean = (ref: RefObject<HTMLElement>) => {

  const [visible, setVisible] = useState(false)

  useEffect(() => {
    const current = ref.current;
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setVisible(true);
        } else {
          setVisible(false);
        }
      });
    });

    if (current) {
      observer.observe(current);
    }

    return () => {
      if (current) {
        observer.unobserve(current);
      }
    }
  }, [ref])

  return visible
}
