import Colors from 'tailwindcss/colors';

export enum RouteNames {
  HOME = '/',
  ABOUT = '/about',
  PROJECTS = '/projects',
  SKILLS = '/skills',
  TOOLS = '/tools',
  SITE_ITEMS = '/site-items',
  AI = '/ai',
  NOT_FOUND = '/not-found'
}

export const routeLabels = {
  [RouteNames.HOME]: 'Home',
  [RouteNames.ABOUT]: 'About',
  [RouteNames.PROJECTS]: 'Projects',
  [RouteNames.SKILLS]: 'Skills',
  [RouteNames.TOOLS]: 'Tools',
  [RouteNames.SITE_ITEMS]: 'This Site',
  [RouteNames.AI]: 'AI',
  [RouteNames.NOT_FOUND]: 'Not Found'
};

export const routes: [string, RouteNames][] = Object.entries(RouteNames);

export interface PageDotColors {
  pointColor: string;
  linkColor: string;
}

export const routeBgMapping: Record<RouteNames, PageDotColors> = {
  [RouteNames.HOME]: {
    pointColor: Colors.red[400],
    linkColor: Colors.red[700]
  },
  [RouteNames.ABOUT]: {
    pointColor: Colors.yellow[400],
    linkColor: Colors.yellow[700]
  },
  [RouteNames.PROJECTS]: {
    pointColor: Colors.stone[400],
    linkColor: Colors.stone[700]
  },
  [RouteNames.SKILLS]: {
    pointColor: Colors.lime[400],
    linkColor: Colors.lime[700]
  },
  [RouteNames.TOOLS]: {
    pointColor: Colors.blue[400],
    linkColor: Colors.blue[700]
  },
  [RouteNames.SITE_ITEMS]: {
    pointColor: Colors.fuchsia[400],
    linkColor: Colors.fuchsia[700]
  },
  [RouteNames.AI]: {
    pointColor: Colors.teal[400],
    linkColor: Colors.teal[700]
  },
  [RouteNames.NOT_FOUND]: {
    pointColor: Colors.red[400],
    linkColor: Colors.red[700]
  }
};
