export enum StylingType {
  PATH_FILL = 'PATH_FILL',
  PATH_STROKE = 'PATH_STROKE',
  TOP_FILL = 'TOP_FILL',
};

export const iconStylingNameMapping: Record<string, StylingType> = {
  'browser.svg': StylingType.PATH_STROKE,
  'datastore.svg': StylingType.TOP_FILL,
  'hashicorp.svg': StylingType.PATH_FILL,

  'architecture.svg': StylingType.TOP_FILL,
  'hammer_wrench.svg': StylingType.TOP_FILL,
  'ruler_pencil.svg': StylingType.TOP_FILL,
  'task.svg': StylingType.TOP_FILL,
}
