import clsx from 'clsx';
import groupBy from 'lodash/groupBy';
import { useMemo } from 'react';
import SVG from 'react-inlinesvg';
import { ParallaxBanner } from 'react-scroll-parallax';
import Tile from '../../components/Tile/Tile';
import { composeFileFolderPath, FileTypes } from '../../constants/files';
import { iconStylingNameMapping, StylingType } from '../../constants/iconStyling';
import { skillTypesLabelsMapping } from '../../constants/skillTypes';
import { Skill, SkillGroup, SkillType } from '../../types/skills.model';
import './SkillItem.css';
import { SkillsTabState } from './Skills.page';
import { BreakpointsResult, useBreakpoint } from '../../hooks/useBreakpoints';

type Props = {
  group: SkillsTabState & SkillGroup,
  skillItems: Skill[],
  className?: string
}

const SkillItem = ({className, group, skillItems}: Props) => {

  const groupedSkillItems: [string, Skill[]][] = useMemo(() => {
    const groupedAllItems = groupBy<Skill>(
      (skillItems ?? []).filter(skill => skill.skillGroupId === group.id),
      sg => sg.type
    );
    return Object.entries<Skill[]>(groupedAllItems)
  }, [skillItems, group]);

  const breaks: BreakpointsResult = useBreakpoint();

  const badgeSize: number = useMemo(() => {
    if (breaks.isLgPlus) return 36;
    if (breaks.isMd) return 34;
    if (breaks.isSm) return 28;
    if (breaks.isXs) return 28;
    return 16;
  }, [breaks]);

  return (
    <div className={className}>
      <Tile className='relative'>
        <ParallaxBanner
          layers={[{ image: `${composeFileFolderPath(FileTypes.IMAGE_SKILL_GROUP)}${group.backgroundImageName}`, speed: -20 }]}
          className="max-h-40 md:max-h-50 lg:max-h-60 xl:max-h-70 aspect-[2/1] md:aspect-[3/1] lg:aspect-[4/1] rounded-2xl object-cover mt-3"
        >
          <div className="absolute inset-0 flex items-center justify-center">
            <div className="text-3xl md:text-4xl font-museo font-semibold text-stone-100 rounded-2xl p-4 bg-neutral-800/80">{group.name}</div>
          </div>
          <div className="groupItemIcon absolute right-1 md:right-5 top-1 md:top-5 bg-neutral-800/70 p-2 rounded-full">
            <SVG
              src={`${composeFileFolderPath(FileTypes.IMAGE_SKILL_GROUP)}${group.imageName}`}
              width="auto"
              height={badgeSize}
              title={`${group.name} Icon`}
              className={clsx(
                {
                  'pathStrokeIcon': iconStylingNameMapping[group.imageName] === StylingType.PATH_STROKE,
                  'pathFillIcon': iconStylingNameMapping[group.imageName] === StylingType.PATH_FILL,
                  'topFillIcon': iconStylingNameMapping[group.imageName] === StylingType.TOP_FILL,
                })
              }
            />
          </div>
        </ParallaxBanner>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-2 pb-3 pt-10">
          {
            groupedSkillItems.map(([type, skills], idx) => (
              <div className="border border-neutral-700 border-dashed rounded-lg p-3" key={`skill-group-type-${idx}`}>
                <div className="text-lg font-semibold pb-4">{skillTypesLabelsMapping(type as SkillType)}</div>
                <div className="">
                  {
                    skills.map((skill, idx, items) => (
                      <div key={`skill-item-${idx}`} className={clsx({'pb-3': items.length !== idx}, "flex flex-row items-center")}>
                        <img className={clsx('blurredAnimation' ,"w-8 md:w-9 mr-6")} src={composeFileFolderPath(FileTypes.IMAGE_SKILL_ITEM) + skill.imageName} alt={skill.name} />
                        <div className="text-lg font-exo">{skill.name}</div>
                      </div>
                    ))
                  }
                </div>
              </div>
            ))
          }
        </div>
      </Tile>
    </div>

  )
}

export default SkillItem