import type { BaseAudit } from './common.model';

/* SKILL */
interface SkillBase {
  name: string;
  imageName: string;
  type: SkillType;
}

export interface Skill extends BaseAudit, SkillBase {
  skillGroupId: string;
}

export interface SkillExtended extends BaseAudit, SkillBase {
  skillGroup: SkillGroup;
}

export interface CreateSkill extends SkillBase {
  skillGroupId: string;
}

export interface UpdateSkill extends SkillBase {
  skillGroupId: string;
}

export enum SkillType {
  FE_FRAMEWORK = 'FE_FRAMEWORK',
  FE_STYLES = 'FE_STYLES',
  FE_TESTS = 'FE_TESTS',
  FE_LIBRARIES = 'FE_LIBRARIES',

  BE_PYTHON = 'BE_PYTHON',
  BE_JS = 'BE_JS',
  BE_JAVA = 'BE_JAVA',
  DB = 'DB',

  CLOUD = 'CLOUD',
  EVENT = 'EVENT',
  CICD = 'CICD',
  ANAYLTICS = 'ANAYLTICS'
}

/* SKILL GROUP */
interface SkillGroupBase {
  name: string;
  imageName: string;
  backgroundImageName: string;
}

export interface SkillGroup extends BaseAudit, SkillGroupBase {
  skillIds: string[];
}

export interface SkillGroupExtended extends BaseAudit, SkillGroupBase {
  skills: Skill[];
}

export interface CreateSkillGroup extends SkillGroupBase {}

export interface UpdateSkillGroup extends SkillGroupBase {}
