import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { SiteItem } from '../../types/siteItems.model';
import type { RootState } from '../store';

type SiteItemsState = {
  mutationSiteItem: SiteItem | null;
};

const initialState: SiteItemsState = {
  mutationSiteItem: null
};

const siteItemsSlice = createSlice({
  name: 'siteItems',
  initialState,
  reducers: {
    setMutationSiteItem: (
      state: SiteItemsState,
      action: PayloadAction<SiteItem | null>
    ) => {
      state.mutationSiteItem = action.payload;
    }
  }
});

export const siteItemsActions = {
  setMutationSiteItem: siteItemsSlice.actions.setMutationSiteItem
};

export const siteItemsSelectors = {
  selectMutationSiteItem: (state: RootState) => state.siteItems.mutationSiteItem
};

export const siteItemsReducer = siteItemsSlice.reducer;
