import { createApi } from '@reduxjs/toolkit/query/react';
import type {
  ApiResponseWrapper,
  GenericResponseResult
} from '../../types/common.model';
import type {
  CreateUser,
  LoginResponse,
  LoginUser,
  UpdateUser,
  User
} from '../../types/users.model';
import { baseQuery } from './baseClient';

export const usersApi = createApi({
  reducerPath: 'usersApi',
  baseQuery: baseQuery({ baseUrl: 'users' }),
  tagTypes: ['User'],
  endpoints: (builder) => ({
    getUserById: builder.query<ApiResponseWrapper<User>, string>({
      query: (id) => ({ url: `/${id}`, method: 'GET' }),
      providesTags: ['User']
    }),
    createUser: builder.mutation<ApiResponseWrapper<User>, CreateUser>({
      query: (data) => ({ url: '', method: 'POST', data }),
      invalidatesTags: ['User']
    }),
    updateUser: builder.mutation<
      ApiResponseWrapper<User>,
      { id: string; data: UpdateUser }
    >({
      query: ({ id, data }) => ({ url: `/${id}`, method: 'PATCH', data }),
      invalidatesTags: ['User']
    }),
    deleteUser: builder.mutation<
      ApiResponseWrapper<GenericResponseResult>,
      string
    >({
      query: (id) => ({ url: `/${id}`, method: 'DELETE' }),
      invalidatesTags: ['User']
    }),
    login: builder.mutation<ApiResponseWrapper<LoginResponse>, LoginUser>({
      query: (data) => {
        return { url: '/login', method: 'POST', data };
      },
      invalidatesTags: ['User']
    })
  })
});

export const {
  useGetUserByIdQuery,
  useCreateUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
  useLoginMutation
} = usersApi;
