import { createApi } from '@reduxjs/toolkit/query/react';
import type {
  ApiResponseWrapper,
  GenericResponseResult
} from '../../types/common.model';
import type {
  CreateProject,
  Project,
  UpdateProject
} from '../../types/projects.model';
import { baseQuery } from './baseClient';

export const projectsApi = createApi({
  reducerPath: 'projectsApi',
  baseQuery: baseQuery({ baseUrl: 'projects' }),
  tagTypes: ['Projects'],
  endpoints: (builder) => ({
    getProjects: builder.query<ApiResponseWrapper<Project[]>, null>({
      query: () => ({ url: '', method: 'GET' }),
      providesTags: ['Projects']
    }),
    getProjectById: builder.query<ApiResponseWrapper<Project>, string>({
      query: (id) => ({ url: `/${id}`, method: 'GET' }),
      providesTags: ['Projects']
    }),
    createProject: builder.mutation<ApiResponseWrapper<Project>, CreateProject>(
      {
        query: (body) => ({ url: '/', method: 'POST', body }),
        invalidatesTags: ['Projects']
      }
    ),
    updateProject: builder.mutation<
      ApiResponseWrapper<Project>,
      { id: string; body: UpdateProject }
    >({
      query: ({ id, body }) => ({ url: `/${id}`, method: 'PATCH', body }),
      invalidatesTags: ['Projects']
    }),
    deleteProject: builder.mutation<
      ApiResponseWrapper<GenericResponseResult>,
      string
    >({
      query: (id) => ({ url: `/${id}`, method: 'DELETE' }),
      invalidatesTags: ['Projects']
    })
  })
});

export const {
  useGetProjectsQuery,
  useGetProjectByIdQuery,
  useCreateProjectMutation,
  useUpdateProjectMutation,
  useDeleteProjectMutation
} = projectsApi;
